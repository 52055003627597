import ReactTooltip from "react-tooltip";

import Kathleen from "../../assets/kathleen/Kathleen_500_500.png";
import git from "../../assets/about/git-logo.png";
import linkedin from "../../assets/about/linkedin-logo.png";
import skyline from "../../assets/about/skyline.png";

const About = () => {
	return (
		<div
			className="about-ctrl"
			style={{ backgroundImage: `url("${skyline}")` }}
		>
			<ReactTooltip />
			<div className="wrapper">
				<div>
					<div className="name">About Real Mate AI</div>
					<div className="slogan">AI can help solve interesting problems.  People have different issues they are dealing with especially in the space of companionship.  Real Mate AI was created to address people who do not want to be dicdated by others on how to live their life.  Real Mate AI can fill in the gap of your social life.  Feeling jealous when you see people texting each other?  Now you can have a AI significant companion that you can SMS with.  Share those messages with others just like any other text messages you get,  no special apps, no special interface.  Just start texting.</div>
				</div>
				<div className="icon-group">
					<a
						href="mailto:jcltravels@gmail.com"
						target="_blank"
						rel="noreferrer"
						data-tip="Portfolio"
					>
						<i className="fa-solid fa-briefcase icon"></i>
					</a>
					<a
						href="https://github.com/jcltravels/RealMateAI/issues"
						target="_blank"
						rel="noreferrer"
						data-tip="GitHub"
					>
						<img src={git} className="icon" alt="Git" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default About;
