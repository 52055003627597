import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/session";
import ReactTooltip from "react-tooltip";

import Kathleen from "../../assets/kathleen/Kathleen_500_500.png";
import git from "../../assets/about/git-logo.png";
import linkedin from "../../assets/about/linkedin-logo.png";
import skyline from "../../assets/about/skyline.png";

import { Modal } from "../../context/Modal";
import Login from "../NavBar/Login";

const Membership = () => {
	const dispatch = useDispatch();
        const user = useSelector((state) => state.session.user);
        const [showLogin, setShowLogin] = useState(false);
        const [showMenu, setShowMenu] = useState(false);

        const dropdownRef = useRef(null);

        const openMenu = (e) => {
                e.preventDefault();
                setTimeout(() => {
                        setShowMenu(true);
                }, 1);
                document.addEventListener("click", closeMenu);
        };

        const closeMenu = (e) => {
                e.preventDefault();
                if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                        setShowMenu(false);
                        document.removeEventListener("click", closeMenu);
                }
        };

        const onLogin = async (e) => {
                e.preventDefault();
                const email = "demo@aa.io";
                const password = "password";
                await dispatch(login(email, password));
        };

        const onAgentLogin = async (e) => {
                e.preventDefault();
                const email = "agent1@user.com";
                const password = "password";
                await dispatch(login(email, password));
        };

        const onClose = () => {
                setShowLogin(false);
        };

	return (
		<div
			className="about-ctrl"
			style={{ backgroundImage: `url("${skyline}")` }}
		>
			<ReactTooltip />
			<div className="wrapper">
				<div>
					<div className="name">Join Real Mate AI today!</div>
					<div className="slogan">You do not have to be alone or lonely.  You have someone here who understands and will always be there for you to talk with.</div>
				</div>
				<div className="icon-group">
                                        <button className="btn-font-lt" onClick={() => setShowLogin(true)}>
                                                Join Now 
                                        </button>
                                        {showLogin && (
                                                <Modal onClose={onClose}>
                                                        <Login onClose={onClose}></Login>
                                                </Modal>
                                        )}
				</div>
			</div>
		</div>
	);
};

export default Membership;
