import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../store/session";
import ReactTooltip from "react-tooltip";

import Kathleen from "../../assets/kathleen/Kathleen_500_500.png";
import git from "../../assets/about/git-logo.png";
import linkedin from "../../assets/about/linkedin-logo.png";
import skyline from "../../assets/about/skyline.png";

import { Modal } from "../../context/Modal";
import Login from "../NavBar/Login";

const Legal = () => {
	const dispatch = useDispatch();
        const user = useSelector((state) => state.session.user);
        const [showLogin, setShowLogin] = useState(false);
        const [showMenu, setShowMenu] = useState(false);

        const dropdownRef = useRef(null);

        const openMenu = (e) => {
                e.preventDefault();
                setTimeout(() => {
                        setShowMenu(true);
                }, 1);
                document.addEventListener("click", closeMenu);
        };

        const closeMenu = (e) => {
                e.preventDefault();
                if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                        setShowMenu(false);
                        document.removeEventListener("click", closeMenu);
                }
        };

        const onLogin = async (e) => {
                e.preventDefault();
                const email = "demo@aa.io";
                const password = "password";
                await dispatch(login(email, password));
        };

        const onAgentLogin = async (e) => {
                e.preventDefault();
                const email = "agent1@user.com";
                const password = "password";
                await dispatch(login(email, password));
        };

        const onClose = () => {
                setShowLogin(false);
        };

	return (
		<div
			className="about-ctrl"
			style={{ backgroundImage: `url("${skyline}")` }}
		>
			<ReactTooltip />
			<div className="wrapper">
				<div>
					<div className="name">Real Mate AI Terms of Service</div>
					<div className="terms">By accessing or using the Real Mate AI app, you agree to be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, do not use the Real Mate AI app.</div>

<div className="terms">The Real Mate AI app is powered by OpenAI's GPT technology. By using the Real Mate AI app, you acknowledge and agree that you are solely responsible for your use of the GPT technology and that your use of the GPT technology must comply with OpenAI's requirements and restrictions, as may be updated from time to time.</div>

<div className="terms">You understand and agree that the Real Mate AI app is provided on an "as is" and "as available" basis, and that Real Mate AI makes no representations or warranties of any kind, express or implied, regarding the availability, accuracy, or completeness of the GPT technology or the Real Mate AI app.</div>

<div className="terms">You agree to use the Real Mate AI app only for lawful purposes, and you acknowledge that your use of the Real Mate AI app may be subject to applicable local, state, national, and international laws and regulations.</div>

<div className="terms">You agree not to use the Real Mate AI app in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Real Mate AI app, or that could damage, disable, overburden, or impair the functioning of the Real Mate AI app in any manner.</div>

<div className="terms">You agree not to use the Real Mate AI app to engage in any activity that is illegal, fraudulent, malicious, or discriminatory, or that infringes on the rights of any third party.</div>

<div className="terms">You understand and agree that Real Mate AIreserves the right, in its sole discretion, to modify or terminate the Real Mate AI app or your access to the Real Mate AI app at any time, without notice, and that Real Mate AI will not be liable to you or any third party for any such modification or termination.</div>

<div className="terms">You acknowledge and agree that Real Mate AI may collect, use, and disclose certain information about you and your use of the Real Mate AI app for the purposes of providing, maintaining, and improving the Real Mate AI app, as well as for compliance with applicable laws and regulations.</div>

<div className="terms">You understand and agree that Real Mate AI may, in its sole discretion and without notice to you, disclose any information about you or your use of the Real Mate AI app to law enforcement or other third parties if required to do so by law or if Real Mate AI reasonably believes that such disclosure is necessary to protect the rights, property, or safety of Real Mate AI, its users, or the public.</div>

<div className="terms">You understand and agree that Real Mate AI will not be liable to you or any third party for any modification, suspension, or termination of the Real Mate AI app or your access to the Real Mate AI app, or for any loss or damage of any kind that may result from such modification, suspension, or termination.</div>

<div className="terms">These Terms constitute the entire agreement between you and Real Mate AI regarding the use of the Real Mate AI app, and they supersede any and all prior or contemporaneous communications and proposals, whether oral or written, between you and Real Mate AI. These Terms may be amended by Real Mate AI at any time and without notice, and your continued use of the Real Mate AI app after any such amendments will constitute your acceptance of the amended Terms.</div>

<div className="terms">If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions will remain in full force and effect.</div>

<div className="terms">The failure of Real Mate AI to enforce any right or provision of these Terms will not be deemed a waiver of such right or provision.</div>

<div className="terms">These Terms are governed by the laws of the State of California, and any disputes arising out of or in connection with these Terms or the Real Mate AI app will be resolved in the courts of the State of California.</div>

<div className="terms">By accessing or using the Real Mate AI app, you consent to the jurisdiction and venue of the courts of the State of California for any such disputes.</div>

<div className="terms">If you have any questions or concerns about these Terms, please contact us.</div>
				</div>
				<div className="icon-group">
                                        <button className="btn-font-lt" onClick={() => setShowLogin(true)}>
                                                Join Now 
                                        </button>
                                        {showLogin && (
                                                <Modal onClose={onClose}>
                                                        <Login onClose={onClose}></Login>
                                                </Modal>
                                        )}
				</div>
			</div>
		</div>
	);
};

export default Legal;
