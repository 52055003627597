import { NavLink } from "react-router-dom";

import logo from "../../assets/logo-blue.svg";
import footer from "../../assets/footer-art.svg";

const Footer = () => {
	return (
		<footer className="footer-ctrl">
			<div className="footer-tech">
                                <NavLink to="/about" className="btn-font-lt">
                                        About
                                </NavLink>
                                <NavLink to="/legal" className="btn-font-lt">
                                        Legal 
                                </NavLink>
                                <NavLink to="/membership" className="btn-font-lt">
                                        Become a member
                                </NavLink>
			</div>
			<NavLink to="/about" className="footer-logo-wrap">
				<img className="footer-logo" src={logo} alt="Real Mate AI" /> © 2024 Real Mate AI 
			</NavLink>

			<img src={footer} alt="Footer" />
		</footer>
	);
};

export default Footer;
