import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useNotification } from "../../../context/Notification";

import UploadPhoto from "../UploadPhoto";

import * as sessionActions from "../../../store/session";
import { PatternFormat } from 'react-number-format';

const AgentProfile = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const agent = useSelector((state) => state.session.user);
	const [username, setUsername] = useState("");
	const [office, setOffice] = useState("");
	const [sms_balance, setSms_balance] = useState("");
	const [bio, setBio] = useState("");
	const [phone, setPhone] = useState("");
	const [maxChar, setMaxChar] = useState(2000);
	const [errors, setErrors] = useState([]);
	const [zip, setZip] = useState("");
	const [zipErrors, setZipErrors] = useState([]);

	const { setToggleNotification, setNotificationMsg } = useNotification();
	const [companion_yob, setCompanion_yob] = useState(2006);
	const [companion_gender, setCompanion_gender] = useState("Female");
	const [companion_name, setCompanion_name] = useState("");


	const handleYobChange = (event) => {
	  setCompanion_yob(event.target.value);
	};
	const handleGenderChange = (event) => {
    	setCompanion_gender(event.target.value);
  	};
	const yearToday = (new Date()).getFullYear();
	const yearsList = Array.from(new Array(40),( val, index) => yearToday - 18 - index);

	const genders = ["Male", "Female"];

	const undo = (e) => {
		e.preventDefault();
		setErrors([]);
		setUsername(agent?.username);
		setOffice(agent?.office);
		setSms_balance(agent?.sms_balance);
		setBio(agent?.bio);
		setPhone(agent?.phone);
		setCompanion_name(agent?.companion_name);
		setCompanion_gender(agent?.companion_gender);
		setCompanion_yob(agent?.companion_yob);
	};

	const removeServiceArea = async (zipcode) => {
		setZipErrors([]);
		const data = await dispatch(sessionActions.removeServiceArea(zipcode));
		if (data.errors) {
			setZipErrors(data.errors);
		} else {
			setZip("");
		}
	};

	const addServiceAreas = async (e) => {
		e.preventDefault();
		setZipErrors([]);
		const payload = { zip };

		const data = await dispatch(sessionActions.addServiceArea(payload));
		if (data.errors) {
			setZipErrors(data.errors);
		} else {
			setZip("");
		}
	};

        const goToStripe = async (e) => {
                e.preventDefault();
                // use history to redirect
                //history.push(`https://buy.stripe.com/4gw3cR8Ul1TMb28cMM`);
                window.open(`https://buy.stripe.com/4gw3cR8Ul1TMb28cMM?client_reference_id=` + agent?.id, `_blank`);
        };

	const handleSubmit = async (e) => {
		setErrors([]);
		e.preventDefault();
		const payload = {
			username,
			office,
			sms_balance,
			bio,
			phone,
			companion_name,
			companion_yob,
			companion_gender,
		};

		const data = await dispatch(sessionActions.updateThisUser(payload));
		if (!data.errors) {
			// Notification if updated
			setToggleNotification("");
			setNotificationMsg("Profile updated");

			setTimeout(() => {
				setToggleNotification("notification-move");
				setNotificationMsg("");
			}, 2000);
		} else {
			setErrors(data.errors);
		}
	};

	useEffect(() => {
		setUsername(agent?.username);
		setOffice(agent?.office);
		setSms_balance(agent?.sms_balance);
		setBio(agent?.bio);
		setPhone(agent?.phone);
		setCompanion_name(agent?.companion_name);
		setCompanion_yob(agent?.companion_yob);
		setCompanion_gender(agent?.companion_gender);
	}, [agent]);

	useEffect(() => {
		if (bio?.length) {
			setMaxChar(2000 - bio?.length);
		} else {
			setMaxChar(2000);
		}
	}, [bio]);

	return (
		<div className="agent-profile-div">
			<form className="agent-ctrl">
				<div className="split">
					<div className="left">
						<UploadPhoto />
						<label className="agent-profile">
							<div className="about">Full Name</div>
							<input
								maxLength="40"
								className="name agent-profile-input"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								placeholder="Full Name"
								required
							/>
						</label>
						<div className="agent-profile">
							<div className="about">About</div>
							<div>
								<textarea
									maxLength="2000"
									className="textarea"
									value={bio}
									onChange={(e) => setBio(e.target.value)}
									rows="4"
									placeholder="Introduce yourself"
								/>
								<div className="error-list">
									{maxChar} characters left (max 2,000)
								</div>
							</div>
						</div>
					</div>
					<div className="agent-profile gap40">
						<div className="gap15">
							<div className="about">Contact</div>
							<div className="phone">
								Phone{" "}
								<PatternFormat 
								   type="tel"
								   format="+1 ###-###-####" 
								   className="agent-input"
								   mask="_" 
								   value={phone}
								   onValueChange={value => setPhone(value.formattedValue)}
								   required
								/>
							</div>
							<div className="phone">You can communicate with your companion only using this registered phone number.</div>
							<div className="phone">{agent?.email}</div>
							</div>
                                    <label className="agent-profile">
                                            <div className="phone">Text your companion at</div>
                                            <div className="about"><a className="reviews" href="sms:9496825966">(949) 682-5966</a></div>
                                    </label>
                                    <label className="agent-profile">
                                            <div className="phone">SMS Balance</div>
                                            <div className="about">{agent?.sms_balance}</div>
                                            <div className="btn-wrap-lt">
                                            	<button type="button" className="btn" onClick={goToStripe}>Add Balance</button>
                                            </div>
                                    </label>
                                    <label className="agent-profile">
										<div className="about">Companion Name</div>
										<input
											maxLength="40"
											className="name agent-profile-input"
											value={companion_name}
											onChange={(e) => setCompanion_name(e.target.value)}
											placeholder="Jane Doe"
											required
										/>
									</label>
									<label className="agent-profile">
                                            <div className="about">Year of birth</div>
                                            <div className="about">
										      <select className="about" value={companion_yob} onChange={handleYobChange}>
										        {yearsList.map((year) => (
										          <option key={year} value={year}>
										            {year}
										          </option>
										        ))}
										      </select>
                                            </div>
                                    </label>
                                    <label className="agent-profile">
                                            <div className="about">Gender</div>
                                            <div className="about">
										      <select className="about" value={companion_gender} onChange={handleGenderChange}>
										        {genders.map((gender) => (
										          <option key={gender} value={gender}>
										            {gender}
										          </option>
										        ))}
										      </select>
                                            </div>
                                    </label>
							</div>
                            <div className="btn-wrap-lt">
                                    <div className="error-list">
                                            {errors.map((err) => (
                                                    <div key={err}>{err}</div>
                                            ))}
                                    </div>
                                    <button type="button" className="btn btn-bl" onClick={undo}>
                                            Cancel
                                    </button>
                                    <button type="button" className="btn" onClick={handleSubmit}>
                                            Update
                                    </button>
                            </div>
						</div>
			</form>
		</div>
	);
};

export default AgentProfile;
