import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import Footer from "./Footer";

const Splash = () => {
	const history = useHistory();

	const [search, setSearch] = useState("");
	const [searchList, setSearchList] = useState([]);
	const [searchFiltered, setSearchFiltered] = useState([]);
	const [error, setError] = useState();
	const [placeholder, setPlaceholder] = useState(
		"Enter an address, city, or ZIP code"
	);

	const searchDivRef = useRef();
	const searchDDRef = useRef();

	const directSearch = (term) => {
		setError("");
		const searchTerm = term.split(" ").join("-");
		history.push(`/search/${searchTerm}`);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (search.length > 0) {
			setError("");
			const searchTerm = search.split(" ").join("-");
			history.push(`/search/${searchTerm}`);
		} else {
			setError("Please enter address, city, or zip code to begin");
		}
	};

	const goToAgentSearch = async (e) => {
		e.preventDefault();
		// use history to redirect
		history.push(`/Membership`);
	};

	useEffect(() => {
		fetch("/api/search/terms")
			.then((res) => res.json())
			.then((res) => setSearchList(res.terms))
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		const filtered = searchList.filter((term) =>
			term.toLowerCase().includes(search.toLowerCase())
		);
		setSearchFiltered(filtered);
	}, [search, searchList]);

	return (
		<>
			<main className="splash-ctrl">
				<form className="splash-search-wrap" onSubmit={handleSubmit}>
					<div className="splash-search-title">Find a great companion</div>
					<div className="splash-search-subtitle">Finding the perfect companion does not have to be hard.  Real Mate AI was created with this in mind.  Your AI companion can help you gain self esteem.  Share conversations using normal SMS (native text messaging) on your phone.  Join today and try it out for FREE.</div>
					<div className="splash-search-subtitle">
							<button
								type="button"
								className="splash-agent-button"
								onClick={goToAgentSearch}
							>
								Connect with a companion 
							</button>
					</div>
				</form>
			</main>
			<Footer />
		</>
	);
};

export default Splash;
